import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/index.vue'),
    meta: {
      title: '初始',
      keepAlive: true
    },
    children:[
      // 首页
      {
        path: 'home',
        name: 'Home',
        component: () => import( '@/views/page/home.vue'),
        meta: {
          title: '首页',
          keepAlive: true
        }
      },
      // 景区介绍
      {
        path: 'introduce',
        name: 'Introduce',
        component: () => import( '@/views/page/introduce.vue'),
        meta: {
          title: '景区介绍',
          keepAlive: true,
          defaultChildPath:'/introduce/overview'
        },
        children:[
          {
            path: 'overview',
            name:'Overview',
            component: () => import( '@/views/page/introduceComponents/overview.vue'),
            meta: {
              title: '连岛概况',
              keepAlive: true
            }
          },
          {
            path: 'view',
            name:'IntroduceView',
            component: () => import( '@/views/page/introduceComponents/IntroduceView.vue'),
            meta: {
              title: '连岛美景',
              keepAlive: true
            }
          },
          {
            path: 'humanity',
            name:'IntroduceHumanity',
            component: () => import( '@/views/page/introduceComponents/IntroduceHumanity.vue'),
            meta: {
              title: '连岛人文',
              keepAlive: true
            }
          },
          {
            path: 'video',
            name:'IntroduceVideo',
            component: () => import( '@/views/page/introduceComponents/IntroduceVideo.vue'),
            meta: {
              title: '连岛影音',
              keepAlive: true
            }
          },
          {
            path: 'honor',
            name:'Honor',
            component: () => import( '@/views/page/introduceComponents/honor.vue'),
            meta: {
              title: '连岛荣誉',
              keepAlive: true
            }
          }
        ]
      },
      // 动态资讯
      {
        path: 'information',
        name: 'information',
        component: () => import( '@/views/page/information.vue'),
        meta: {
          title: '动态资讯',
          keepAlive: true,
          defaultChildPath:'/information/notice'
        },
        children:[
          {
            path: 'notice',
            name:'notice',
            component: () => import( '@/views/page/informationComponents/notice.vue'),
            meta: {
              title: '通知公告',
              keepAlive: true
            }
          },
          {
            path: 'dynamic',
            name:'dynamic',
            component: () => import( '@/views/page/informationComponents/dynamic.vue'),
            meta: {
              title: '景区动态',
              keepAlive: true
            }
          },
          {
            path: 'activity',
            name:'activity',
            component: () => import( '@/views/page/informationComponents/activity.vue'),
            meta: {
              title: '最新活动',
              keepAlive: true
            }
          },
          {
            path: 'story',
            name:'story',
            component: () => import( '@/views/page/informationComponents/story.vue'),
            meta: {
              title: '幕后故事',
              keepAlive: true
            }
          },
          {
            path: 'tourism',
            name:'tourism',
            component: () => import( '@/views/page/informationComponents/tourism.vue'),
            meta: {
              title: '文明旅游',
              keepAlive: true
            }
          }
        ]
      },
      // 旅游攻略
      {
        path: 'tourismTourism',
        name: 'tourismTourism',
        component: () => import( '@/views/page/tourismTourism.vue'),
        meta: {
          title: '旅游攻略',
          keepAlive: true,
          defaultChildPath:'/tourismTourism/map'
        },
        children:[
          {
            path: 'map',
            name:'map',
            component: () => import( '@/views/page/tourismTourism/map.vue'),
            meta: {
              title: '景区地图',
              keepAlive: true
            }
          },
          {
            path: 'routes',
            name:'routes',
            component: () => import( '@/views/page/tourismTourism/routes.vue'),
            meta: {
              title: '线路推荐',
              keepAlive: true
            }
          },
          {
            path: 'traffic',
            name:'traffic',
            component: () => import( '@/views/page/tourismTourism/traffic.vue'),
            meta: {
              title: '交通指南',
              keepAlive: true
            }
          },
          {
            path: 'accommodation',
            name:'accommodation',
            component: () => import( '@/views/page/tourismTourism/accommodation.vue'),
            meta: {
              title: '住宿指南',
              keepAlive: true
            }
          },
          {
            path: 'food',
            name:'food',
            component: () => import( '@/views/page/tourismTourism/food.vue'),
            meta: {
              title: '美食指南',
              keepAlive: true
            }
          },
          {
            path: 'shopping',
            name:'shopping',
            component: () => import( '@/views/page/tourismTourism/shopping.vue'),
            meta: {
              title: '欢购指南',
              keepAlive: true
            }
          },
          {
            path: 'entertainment',
            name:'entertainment',
            component: () => import( '@/views/page/tourismTourism/entertainment.vue'),
            meta: {
              title: '休闲娱乐',
              keepAlive: true
            }
          },
          {
            path: 'share',
            name:'share',
            component: () => import( '@/views/page/tourismTourism/share.vue'),
            meta: {
              title: '游客分享',
              keepAlive: true
            }
          }
        ]
      },
      // 旅游服务
      {
        path: 'services',
        name: 'services',
        component: () => import( '@/views/page/services.vue'),
        meta: {
          title: '旅游服务',
          keepAlive: true,
          defaultChildPath:'/services/travelNotice'
        },
        children:[
          {
            path: 'travelNotice',
            name:'travelNotice',
            component: () => import( '@/views/page/services/travelNotice.vue'),
            meta: {
              title: '浏览须知',
              keepAlive: true
            }
          },
          {
            path: 'tourGuideServices',
            name:'tourGuideServices',
            component: () => import( '@/views/page/services/tourGuideServices.vue'),
            meta: {
              title: '导游服务',
              keepAlive: true
            }
          },
          {
            path: 'carService',
            name:'carService',
            component: () => import( '@/views/page/services/carService.vue'),
            meta: {
              title: '景交车服务',
              keepAlive: true
            }
          },
          {
            path: 'audioGuide',
            name:'audioGuide',
            component: () => import( '@/views/page/services/audioGuide.vue'),
            meta: {
              title: '语音导览',
              keepAlive: true
            }
          },
          {
            path: 'surveyQuestionnaire',
            name:'surveyQuestionnaire',
            component: () => import( '@/views/page/services/surveyQuestionnaire.vue'),
            meta: {
              title: '调查问卷',
              keepAlive: true
            }
          },
        ]
      },
      // 在线预订
      {
        path: 'booking',
        name: 'booking',
        component: () => import( '@/views/page/booking.vue'),
        meta: {
          title: '在线预订',
          keepAlive: true,
        },
      },
      // 景区VR
      {
        path: 'scenicAreaVR',
        name: 'scenicAreaVR',
        component: () => import( '@/views/page/scenicAreaVR.vue')
      },
      // 联系我们
      {
        path: 'contact',
        name: 'contact',
        component: () => import( '@/views/page/contact.vue'),
        meta: {
          title: '联系我们',
          keepAlive: true,
        },
      },
      // 天气情况
      {
        path: 'weather',
        name: 'weather',
        component: () => import( '@/components/weather.vue'),
        meta: {
          title: '天气情况',
          keepAlive: true,
        },
      },
      // 详情
      {
        path: 'article',
        name: 'article',
        component: () => import( '@/components/article/article.vue')
      },
      // 搜索
      {
        path: 'search',
        name: 'search',
        component: () => import( '@/components/article/search.vue')
      }
    ]
  }
  
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(), // 本地
  routes
})
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})
export default router
