import { createStore } from 'vuex'

export default createStore({
  state: {
    actImg:[],
    actList:[]
  },
  getters: {
    actImg(state){
      return state.actImg
    },
    actList(state){
      return state.actList
    }
  },
  mutations: {
    saveActImg(state,data){
      state.actImg = data
    },
    saveActList(state,data){
      state.actList = data
    }
  },
  actions: {
  },
  modules: {
  }
})
