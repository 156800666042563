<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
      <router-view></router-view>
</template>
<script>
export default {
  name: 'App',

  data(){
    return {
      images: [
            
      ],
    }
  },
  created(){
    this.getList()
    // var isAndroid = win.navigator.appVersion.match(/android/gi);
    // console.log(isAndroid)
    let userAgent = navigator.userAgent || navigator.vendor || window.opera
    if(/android/i.test(userAgent)){
      var meta = document.querySelector('meta[name="viewport"]');
      meta.setAttribute('content', 'initial-scale=' + 0.5);
    }
    
  },
  mounted(){
    
  },
  methods:{
    getList(){
      let type= '最新活动'
      this.$http.get(`liandao/website/article/page?type=${type}&pageNum=1&pageSize=999`).then(res=>{
        let dataList = res.data.rows
        let arr = {
            images:[[],[],[],[]],
            dataArr:[[],[],[],[]]
          }
        dataList.forEach(ele => {
          let month = Number(ele.activityTime.slice(5,7))
          if(month>=1&&month<4){
            arr.images[0].push(this.$baseUrl+ele.img)
            arr.dataArr[0].push(ele)
          }
          if(month>=4&&month<7){
            arr.images[1].push(this.$baseUrl+ele.img)
            arr.dataArr[1].push(ele)
          }
          if(month>=7&&month<10){
            arr.images[2].push(this.$baseUrl+ele.img)
            arr.dataArr[2].push(ele)
          }
          if(month>=10&&month<=12){
            arr.images[3].push(this.$baseUrl+ele.img)
            arr.dataArr[3].push(ele)
          }
          
          // this.images.push(this.$baseUrl+ele.img)
        });
        sessionStorage.setItem('actimg',JSON.stringify(arr.images))
        sessionStorage.setItem('actList',JSON.stringify(arr.dataArr))
        // this.$store.commit('saveActImg',arr.images)
        // this.$store.commit('saveActList',arr.dataArr)
      })
    }
  }
}
</script>
<style lang="scss">
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  overflow-y: hidden;
  overflow-x: hidden;
}

/* nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */
</style>
