module.exports={
    language: '日语',
    menu:{
        home: 'トップページ',
        introduce: '観光スポット案内',
        tourismTourism: '旅行ガイド',
        booking: 'オンラインチケット予約',
        scenicAreaVR: 'バーチャルツアー',
        contact :'お問い合わせ',
        search: '検索'
    },
    // 首页
    home:{
        cxzs: '旅行アシスタント',
        cktq: '観光地の天候や観光客数を確認',
        wtld: '連島へようこそ！',
        ldjs: '観光スポット案内',
        psld: '美しい連島の詩的な景色',
        jsld: '連島景勝地の概要',
        jsldzy:'連島は山が曲がりくねり、峰が連なり、山々は青々と茂り、自然の風景が美しく、文化的な遺産が豊かで、生態環境が優れており、漁村の人々は純朴で、まるで美しい海の絵のようです！連島観光地には山、海、島、森、岩石、砂浜、そして人文景観が集約されており、「春は霧を見て、夏は海を見て、秋は木々を見て、冬は道を見る」と言われています。',
        ts: '旅行ガイド'
    },
    childMenu:{
        overview :"連島の概要",
        view: '連島の美しい景色',
        humanity: '連島の人文景観',
        video: 'ビデオゾーン',
        honor: '連島の誇り',
        map:'観光地地図',
        routes:'回線推奨',
        traffic:'お出かけ',
        accommodation:'ホテル宿泊',
        food:'飲食グルメ',
        shopping:'楽しくショッピング',
        entertainment:'レクリエーション',
        share:'観光客シェア',
        ticket:'チケット予約',
        wctc:'文創特産',
        accommodationBooking:'宿泊予約',
        foodBooking:'グルメ予約'
    },
    overview:{
        paragraph1: '連雲港連島観光地は黄海の浜海州湾畔の連島に位置し、江蘇省最大の海上島であり、海岸線の長さは17.66キロ、岩礁岸線は8キロである。全島の面積は8.1平方キロで、2024年に国家級5 A観光地に選ばれた。海の中にそびえ立って、連雲港港と海を隔てて眺めて、ここには江蘇省唯一の国家級健康型海水浴場である大沙湾遊園地と蘇馬湾生態園の2つの浴場と海上観光に従事するヨットクラブがある。',
        paragraph2: '連島の自然風景は秀麗で魅力的で、青山、碧海、茂林、海蝕奇石、天然砂浜、海島漁村の人文景観を一体に集め、気候は温和で、冬は寒さがなく、夏は猛暑がなく、四季は心地よく、空気は清新で純粋で、マイナスイオンが豊富で、森林被覆率は80%に達した。1年中に国家基準に従って水泳の水温108日に適している。連島は温暖温帯から亜熱帯へ移行する季節風性海洋気候に属し、海州湾漁場に臨み、植物の種類が多く、海産物が豊富で、海食地質が独特な資源優勢を持っている。',
        paragraph3: '連島はかつて波が荒く、上空の鳥が空を遮っていた。古くは鷹遊山と呼ばれ、1992年に建設された6.7キロの堤防は連島を陸続きにした。全島の東西9キロ、面積8平方キロ、森林カバー面積は80%に達した。島の自然は美しく魅力的で、豊かな文化の古韻と人文の歴史を持っていて、まるで美しい海のスクリーンのようです！',
    },
    honor:{
        img1: '2022年ドライブ旅行のベストデスティネーション',
        img2:'2022年江蘇省知能ツーリズムスポット',
        img3:'2022年12月省レベルの文明ツーリズムデモンストレーション機関',
        img4:'2019年11月江蘇省廉政教育基地',
        img5:"2016年6月江蘇省最も美しいランニングルートの連島",
        img6:'2016年3月江蘇省信頼できる消費創造活動先進機関',
        img7:'2015年美しい島トップ10',
        img8:'2012年美しい江蘇ハッピーレコメンドスポット',
        img9:'2008年3月「誠実機関」',
        img10:'2017年 江蘇省の革新力のある新メディアアカウントトップ10',
        img11:'2021年10月 「水韻江蘇」人気スポット・人気ランドマーク',
        img12:'2019年連島の花海',
        img13:'2017年江蘇省のスポーツ公園トップ10',
        img14:'2017年江蘇省「ベスト観光地トップ100」',

    },
    contact:{
        lxwm: 'お問い合わせ',
        zxts:'オンライン苦情',
        lxr: '担当者',
        lxrph:'名前の入力',
        lxdh: '連絡先',
        lxdhph: '電話番号の入力',
        type:'タイプ選択',
        wtms: '問題の説明',
        wtmsph:'フィードバックの入力',
        sczp: '写真のアップロード',
        type1:'サービス体験',
        type2:'スポット提案',
        type3:'環境問題',
        type4:'治安問題',
        type5:'実名告発',
        type6:'その他',
        gxtj: 'ご提出ありがとうございました'
    },
    top:{
        area: '連雲港連島風景区',
        level:'国家5A級観光地',
        language:'言語',
        zh: '中国語',
        en: '英語',
        ko:'韓国',
        jp:'日本語'
    },
    bottom:{
        zxdh:'観光ホットライン',
        dz: '連絡先：連雲港市連雲港区連島鎮のジャンクション',
        ba:'蘇ICP備2021043721号-1',
        bq:'CopyRight © 2018 連雲港連島観光開発有限公司 '
    },
    view:{
        ykzx:'観光客センター',
        gdjd: 'その他の観光スポット'
    },
    tqqk:{
        excellent: '優',
        good:'良',
        in:'中',
        difference:'差分'
    },
    article:{
        time:'公開日時',
        result:'検索結果',
        location:'現在位置'
    },
    booking:{
        order:'今すぐ購入',
        codeOrder:'WeChatエンドスキャンコード'
    }


}