module.exports={
    language: '韩语',
    menu:{
        home: "홈",
        introduce: '명소 인상',
        tourismTourism: '관광 공략',
        booking: '온라인 예매',
        scenicAreaVR: '가상 관광',
        contact :'연락 방법',
        search: '검색'
    },
    // 首页
    home:{
        cxzs: '외출 도우미',
        cktq: '명소 날씨, 관광객량 보기',
        wtld: '렌다오에 오신 것을 환영합니다!',
        ldjs: '명소 인상',
        psld: '아름다운 연도의 시적 경치.',
        jsld: '연도풍경명승지 안내',
        jsldzy: '연도의 구불구불한 산세, 용솟음치는 연봉, 산과 푸른 나무, 수려한 자연 경관, 유서깊은 문화, 우수한 생태 환경, 순박한 어촌 풍속으로 하나의 아름다운 해상 그림 병풍입니다! 연도 명소는 산, 바다, 섬, 숲과 암석, 해변 및 인문 경관을 일체로하며 "봄에는 안개, 여름에는 바다, 가을에는 나무, 겨울에는 길"을 감상한다는 말이 있습니다. "산수의 미, 자연의 미, 녹색의 미\'로, 이곳은 중국 해안 지역에서 비교적 큰 영향력을 가진 해변 관광 및 휴양지가 되었습니다.',
        ts: '관광 공략'
    },
    childMenu:{
        overview :"연도 개황",
        view: '연도 절경',
        humanity: '연도 인문',
        video: '비디오 영역',
        honor: '연도 영예',
        map:'관광지 지도',
        routes:'라인 추천',
        traffic:'교통 외출',
        accommodation:'호텔 숙박',
        food:'음료 요식',
        shopping:'해피 쇼핑',
        entertainment:'레저 오락',
        share:'관광객 공유',
        ticket:'티켓 예약',
        wctc:'문창특산물',
        accommodationBooking:'숙박 예약',
        foodBooking:'음식 예약'
    },
    overview:{
        paragraph1: '황해 바닷가에 위치하는 연운항 련섬(連島) 관광지는 강소성에 최대의 섬으로서 련섬(連島) 해안선 길이는 17.66 km이고 ，암초선 길이는8km이다.면적이 8.1 km²인 련섬(連島)은 2024년에 국가 5A급 관광지로 선정됐다.바다에 있는 련섬(連島)이 연운항 부두하고 바다를 사이에 두고 마주 보며 강소성 유일한 국가급 헬스형 해수육장인 다사만(大沙灣) 놀이 공원하고 소마완(蘇馬灣) 관광지가 있고 바다 구경할 수 있는 요트 클럽도 있다. ',
        paragraph2: '청산, 벽해, 무성한 숲, 기석, 천연 백사장, 어촌을 다 가지고 있는 련섬(連島) 관광지가 아름다우며 겨울에 춥지 않고 여름에 덥지 않는 따뜻한 날씨가 가지고 있으며 삼림 복개율은 80퍼센트에 달해서 공기가 깨끗하고 신선하다.매년 국가 표준 수영온도에 맞는 일수는 108일다. 온대에서 아열대로 과도한 동안기후에 속한   련섬(連島)이 따뜻하며 해주만(海州灣)어장에 인접한다.동식물 종류가 다양하고 해산물 종류가 많으며 해식 지형등 특별 장점이 있다.',
        paragraph3: '옛날에는 련섬(連島)이 파도가 거세고 수많는 새들이 온 하늘을 가렸기 때문에   매유산(鷹游山)라고 불렀다. 1992년 건설한 길이가 6.7km인 제방은 련섬하고 대륙을 연결시킨다. 동쪽에서 서쪽까지 길이가 9km이고 면적이 8km²인 연섬이 삼림 복개율은 80퍼센트에 달한다. 련섬은 자연 풍경이 아름다워서 재미 있는 인문 역사를 가진다.련섬 풍경이 그림 같다.',
    },
    honor:{
        img1: '2022년 최적의 드라이브 여행목적지',
        img2:'2022 장수 스마트 관광 명소',
        img3:'2022.12 성급 문명 관광 시범 단위t',
        img4:'2019.11 장수성 청렴교육기지',
        img5:"2016.6 장수성에서 가장 아름다운 달리기 코스.연도",
        img6:'2016.3 장수성 안심소비 창건활동 선진 단위',
        img7:'2015의 10대 아름다운 해도',
        img8:'2012 아름다운 장수환락추천 명소',
        img9:'2008. 3 성실 단위',
        img10:'2017 장수성 10대 혁신력 뉴미디어 계정',
        img11:'2021.10 수운강소 인터넷 스타 체크인 랜드마크',
        img12:'2019 연도 화해',
        img13:'2017 장수성 10대 체육공원',
        img14:'2017 장수성 "관광 100대"단위',

    },
    contact:{
        lxwm: '연락 방법',
        zxts:'연락인',
        lxr: '연락인',
        lxrph:'당신의 이름',
        lxdh: '휴대폰',
        lxdhph: '휴대폰 번호를 입력하십시오',
        type:'종류 선택',
        wtms: '문제 설명',
        wtmsph:'피드백 입력',
        sczp: '사진 업로드',
        type1:'서비스 체험',
        type2:'관광지 건의',
        type3:'환경 문제',
        type4:'치안 문제',
        type5:'실명 제보',
        type6:'기타 문제',
        gxtj: '제출하여 감사합니다'
    },
    top:{
        area: '연운항 연도 풍경구',
        level:'국가 5A 관광 명소',
        language:'언어',
        zh: '중국어',
        en: '영어',
        ko:'한국어',
        jp:'일본'
    },
    bottom:{
        zxdh:'관광 핫라인',
        dz: '연락 주소：롄 윈강 (연운항) 롄 윈강 (연운항) 랴오 다오 (Town) 타운 정션',
        ba:'소ICP비2021043721호-1',
        bq:'CopyRight © 2018 롄 윈강 리안도 관광 개발 유한 회사 '
    },
    view:{
        ykzx:'관광객 센터',
        gdjd: '더 많은 명소'
    },
    tqqk:{
        excellent: '우수',
        good:'양',
        in:'가운데',
        difference:'차이'
    },
    article:{
        time:'발표 시간',
        result:'검색 결과',
        location:'현재 위치'
    },
    booking:{
        order:'지금 구매',
        codeOrder:'위챗 엔드 스캔 코드 구매'
    }
}