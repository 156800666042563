module.exports={
    language: '英语',
    menu:{
        home: 'Home',
        introduce: 'Impressions',
        // information: 'Dynamic information',
        tourismTourism: 'Tourist Plans',
        // services: 'Tourism services',
        booking: 'Online Booking',
        scenicAreaVR: 'Virtual Tourism',
        contact :'Contact Us',
        search: 'Search'
    },
    // 首页
    home:{
        cxzs: 'Travel assistant',
        cktq: 'Check weather & visitor volume',
        wtld: 'Welcome to Liandao Island!',
        ldjs: 'Scenic Area Impressions',
        psld: 'Poetic scenery in beautiful Liandao Island.',
        jsld: 'Introduction of Liandao Island Scenic Spot',
        jsldzy:"Lian Island's topography is winding, with peaks and ridges undulating. The mountains are lush with greenery, and the trees are a vibrant shade of green, creating a stunning natural landscape. It boasts a rich cultural heritage, excellent ecological environment, and the simple and genuine character of the fishing village.",
        ts:'Tourism Strategy'
    },
    childMenu:{
        overview :"Overview",
        view: 'Attractions',
        humanity: 'Culture',
        video: 'Videos',
        honor: 'Honors',
        map:'Scenic Area Map',
        routes:'Recommended routes',
        traffic:'Transportation',
        accommodation:'Accommodation',
        food:'Catering',
        shopping:'Shopping',
        entertainment:'Leisure & Amusement',
        share:'Tourist sharing',
        ticket:'Ticket booking',
        wctc:'Cultural and creative specialties',
        accommodationBooking:'Accommodation booking',
        foodBooking:'Food reservation'
    },
    overview: {
        paragraph1: 'Lianyungang Liandao Island Scenic Spot is located in Liandao Island. Bordering Haizhou Bay and the coast of the Huanghai Sea, Liandao Island is the largest offshore island in Jiangsu Province, with a coastline of 17.66 kilometers and a reef coastline of 8 kilometers. The whole island covers an area of 8.1 square kilometers and has become a National 5A Scenic Spot since 2024. Seated in the sea, the island faces Lianyungang Port across the sea. There are the only national-level healthy bathing beaches in Jiangsu Province - Dasha Bay Amusement Park and Suma Bay Ecological Park, as well as yacht club engaged in sea sightseeing.',
        paragraph2: 'The natural scenery of Liandao Island is beautiful and charming, integrating green hills, blue sea, thick wood, marine-corrosion rocks, natural beaches and cultural landscapes of fishing villages. The climate is mild, not cold in winter and no hot summer. The four seasons are pleasant, the air is fresh and pure, rich in negative ions, and the forest coverage rate reaches 80%. There are 108 days in a year enjoying suitable swimming water temperature as per national standard. The climate here is monsoon marine climate in transition from warm temperate zone to subtropical zone. The island is close to Haizhou Bay fishing ground. It has the following resource advantages: various plant species, abundant seafood and unique marine erosion geology.',
        paragraph3:'Liandao Island used to be choppy, and birds covered the sky. It was called “Yingyou Mountain” in ancient times. The 6.7 km-long sea levee built in 1992 connected Liandao Island with the land. The whole island is 9 kilometers long from east to west and covers an area of 8 square kilometers, with 80% forest coverage. The natural scenery of the island is beautiful and charming. With the charm of ancient Chinese culture and humanistic history, Liandao Island is just like a beautiful painted screen on sea!',
    },
    honor:{
        img1: 'Best Self-Drive Travel Destination of 2022',
        img2:'Smart Tourism Scenic Area of 2022, Jiangsu',
        img3:'December 2022, Provincial Civilized Tourism Demonstration Unit',
        img4:'November 2019, Jiangsu Province Integrity Education Base',
        img5:"June 2016, Jiangsu's Most Beautiful Running Route - Lian Island",
        img6:'March 2016, Advanced Unit for Jiangsu Province Trustworthy Consumption Initiative',
        img7:'Top 10 Beautiful Islands of 2015',
        img8:'Wonderful Jiangsu Recommended Scenic Area of 2012',
        img9:'March 2008, Honest Unit',
        img10:'Top 10 Innovative New Media Accounts in Jiangsu 2017 ',
        img11:'October 2021, Shuiyun Jiangsu Internet Celebrity Check Point and Internet Celebrity Landmark',
        img12:'2019, Lian Island Flower Sea',
        img13:'2017, Top 10 Sports Parks in Jiangsu',
        img14:"2017, Jiangsu's 'Top 100 Tourism Enterprises'",

    },
    contact:{
        lxwm: 'Contact Us',
        zxts:'Online Complaint',
        lxr: 'Contact',
        lxrph:'Please input your name',
        lxdh:'Contact phone number',
        lxdhph: 'Please input your phone number',
        type:'Select categories',
        wtms: 'Issue Description',
        wtmsph:'Please input your feedback',
        sczp: 'Photo Upload',
        type1:'Service experience',
        type2:' Scenic area suggestions',
        type3:'Environmental issues',
        type4:'Public safety issues',
        type5:'Real-name reports',
        type6:'Other issues',
        gxtj: 'Thanks for submission'
    },
    top:{
        area: 'Lianyungang Lian Island Scenic Area',
        level:'National 5A tourist attractions',
        language:'Languages',
        zh: 'CH',
        en: 'English',
        ko:'Korea',
        jp:'Japan'
    },
    bottom:{
        zxdh:'Consulting Hotline',
        dz: 'Add: Lianyungang, Lianyun District, Jiangsu province',
        ba:'Jiangsu ICP Filing No. 2021043721-1',
        bq:'Copyright: All rights reserved Lianyungang Lian Island Tourism Development Co., Ltd'
    },
    view:{
        ykzx:'Tourist Center',
        gdjd: 'More attractions'
    },
    tqqk:{
        excellent: 'excellent',
        good:'good',
        in:'in',
        difference:'difference'
    },
    article:{
        time:'Time of release',
        result:'Search Result',
        location:'Current Location'
    },
    booking:{
        order:'buy now',
        codeOrder:'WeChat end scan code'
    }

}