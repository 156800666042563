module.exports={
    language: '中文',
    menu:{
        home: '景区首页',
        introduce: '景区介绍',
        // information: '动态资讯',
        tourismTourism: '旅游攻略',
        // services: '旅游服务',
        booking: '在线预定',
        scenicAreaVR: '景区VR',
        contact :'联系我们',
        search: '请输入内容进行检索',
        
    },
    // 首页
    home:{
        cxzs: '出行小助手',
        cktq: '查看景区天气、游客量',
        yxld: '印象连岛',
        yxld_desc:'美丽的连岛·浪漫的开始',
        ldzx: '连岛资讯',
        ldzx_desc: '一手掌握，连岛动态',
        gddt: '更多动态',
        wzld: '玩转连岛',
        wzld_desc: '吃玩住行，全面的旅游指南',
        lxtj: '路线推荐'
    },
    childMenu:{
        overview :"连岛概况",
        view: '连岛美景',
        humanity: '连岛人文',
        video: '连岛影音',
        honor: '连岛荣誉',
        map:'景区地图',
        routes:'线路推荐',
        traffic:'交通指南',
        accommodation:'住宿指南',
        food:'美食指南',
        shopping:'欢购指南',
        entertainment:'休闲娱乐',
        share:'游客分享',
        ticket:'门票预订',
        wctc:'文创特产',
        accommodationBooking:'住宿预订',
        foodBooking:'美食预订'
    },
    honor:{
        img1: '2022自驾游最佳目的地',
        img2:'2022江苏智慧旅游景区',
        img3:'2022.12省级文明旅游示范单位',
        img4:'2019.11江苏省廉政教育基地',
        img5:"2016.6江苏最美跑步线路.连岛",
        img6:'2016.3江苏省放心消费创建活动先进单位',
        img7:'2015十大美丽海岛',
        img8:'2012美好江苏欢乐推荐景区',
        img9:'2008.3诚信单位',
        img10:'2017江苏省十佳创新力新媒体账号',
        img11:'2021.10水韵江苏网红打卡点网红地标',
        img12:'2019连岛花海',
        img13:'2017江苏省十佳体育公园',
        img14:'2017江苏“旅游百佳”单位',

    },
    contact:{
        lxwm: '联系我们',
        zxts:'在线投诉',
        lxr: '联系人',
        lxrph:'请输入您的姓名',
        lxdh: '联系电话',
        lxdhph: '请输入您的手机号',
        type:'选择类型',
        wtms: '问题描述',
        wtmsph:'请输入您的反馈',
        sczp: '上传照片',
        type1:'服务体验',
        type2:'景区建议',
        type3:'环境问题',
        type4:'治安问题',
        type5:'实名举报',
        type6:'其他问题',
        gxtj: '感谢提交'
    },
    top:{
        area: '连云港连岛风景区',
        level:'国家AAAAA级旅游景区',
        language:'语言',
        zh: '中文',
        en: 'English',
        ko:'한국어',
        jp:'日本語'
    },
    view:{
        ykzx:'游客中心',
        gdjd: '更多景点'
    },
    tqqk:{
        excellent: '优',
        good:'良',
        in:'中',
        difference:'差'
    },
    article:{
        time:'发布时间',
        result:'搜索结果',
        location:'当前位置'
    },
    booking:{
        order:'点击预订',
        codeOrder:'扫码预订'
    }

}