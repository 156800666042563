import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import './assets/css/common.css'
import './lib/lib-flexible'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 动态效果
import 'hover.css'
// import 'animate.css'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import axios from 'axios'
import { createI18n } from 'vue-i18n';
// import vConsole from 'vconsole'
// const vconsole = new vConsole()
import jquery from 'jquery'
const app = createApp(App)
// 本地测试
// const baseUrl = 'http://192.168.3.11:8080' 
// 线上
const baseUrl = 'https://admin.liandaojq.com/prod-api'
axios.defaults.baseURL = baseUrl
app.use(ElementPlus)
app.config.globalProperties.$http = axios
app.config.globalProperties.$baseUrl = baseUrl
import $ from 'jquery';
import jqueryPage from '@/jqueryHelp/jqueryPage.js'
// import "@/assets/css/jqueryCss/page.css";
const i18n = createI18n ({
    locale:'zh',
    messages:{
      //中文 
      'zh':require('./lang/zh.js'),
      //英文
      'en':require('./lang/en.js'),
      //韩语
      'ko':require('./lang/ko.js'),
      // 日语
      'jp':require('./lang/jp.js'),
    }
})
app.use(store).use(router).use(i18n).use(ElementPlus).mount('#app')

